/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #161616;
$primary: #FEFEFE;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4D4D4D;
$gray: #181818;
$accent: #ffaa17;
$miami: #04a3a0;

$light-gray: #f4f2ee;




.text-black h2 {
   color: #181818; 

}

.images-box {
	display: flex;
	position: relative;
    justify-content: center;
}

.images-box-item {
	display: flex;
 
	position: relative;
    justify-content: center;
}

.images-box-item img {
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.images-box-item-left {
	position: absolute;
	left: 0;
	right: 35%;
	bottom: -20px;
	border-top: 20px solid #ffffff;
	border-bottom: 20px solid #ffffff;
	border-right: 20px solid #ffffff;
}








/* -------------------------------------------

timer

------------------------------------------- */

.mil-timer-text {
    text-align: center;

    @media screen and (max-width: 1200px) {
        text-align: center;
    }

    @media screen and (max-width: 500px) {
        text-align: center;
    }
}

.mil-timer {
    display: flex;

    justify-content: center;

    & .mil-timer-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 20px;

        &:after {
            content: ":";
            font-size: 22px;
            color: $light-gray;
            position: absolute;
            right: -25px;
            top: 25px;
        }

        &:last-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        & .mil-timer-number {
            color: $light-gray;
            display: block;
            width: 80px;
            height: 80px;
            border: solid 1px $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: center;
        
    }

    @media screen and (max-width: 500px) {
        flex-direction: row;
        justify-content: center;
        font-size: 12px;


        & .mil-timer-item {
            display: flex;
            font-size: 12px;

            margin-bottom: 30px;

            &:after {
                display: none;
            }
        }
    }
}



.wrap {
    text-align: center;
    padding: 20px;
    background: $miami; 
    // background: linear-gradient(135deg,#6394ff 0%,#0a193b 100%);
    border-radius: 0.5rem;
    color: #ffffff;
    box-shadow: 7px 7px 30px -5px rgba(0,0,0,0.1);
   h2 {
    color: rgb(255, 255, 255);
   }
}

.wrap:hover {
    background: $miami;

    color: white;
    h2{
        background: $miami; 
               color: rgb(255, 213, 0);
    }
}

.ico-wrap {
    margin: auto;
}


.vcenter {
   
    margin: auto
}


h2 {
    color: #161616;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.display-5 {
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 1.4rem;
}
.mbr-bold {
    font-weight: 700;
}

 p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 25px;
}
.display-6 {
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 1re}